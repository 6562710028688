'use client'
import { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import logo from '../assets/images/logo.png'

import {
   Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import { DynamicHeroIcon } from './Helper';
import { AuthContext } from '../store/auth-context';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SideBar() {
  const navigate = useNavigate()
  const {token, loading, isAuthenticated} = useContext(AuthContext);
  
  useEffect(() => {
    //console.log("Sidebar useEffect - " + JSON.stringify([token, loading, isAuthenticated]))
    if(!token || token && !loading && Object.keys(token).length === 0) {
      navigate('/login')
    }
  },[token, loading, isAuthenticated])
  return (
    <>
      <div>
        <NavBar menu={token.menu}></NavBar>
        <div className="lg:pl-72">
          <ProfileDropDown></ProfileDropDown>
          <main>
            <div className="px-4 sm:px-6 lg:px-8">
              <TopBar></TopBar>
              <div className="py-10">
                <Outlet />
              </div>
            </div>
          </main>     
        </div>
      </div>
    </>
  )
}
function TopBar(){
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState('')
  const {pageGroupId, token} = useContext(AuthContext);
  const [pages, setpages] = useState([])
  useEffect(() => {
    console.log("TopBar useEffect - " + JSON.stringify([pageGroupId]))
    token.menu.map((page) => {
      if(page.pageGroupId === pageGroupId){
        setpages(page.pages)
        setCurrentPage(page.pages[0].detail.element)
        navigate("/home/" + page.pages[0].detail.path)
      }
    })
  },[pageGroupId])

  return (
    <div>
      <div className="hidden sm:block">
        <nav aria-label="Tabs" className="flex space-x-4">
          {pages?.map((page, index) => (
            <a
              key={index}
              onClick={(e) => {
                e.preventDefault()
                setCurrentPage(page.detail.element)
                navigate("/home/" + page.detail.path)
              }}
              aria-current='page'
              className={classNames(
                (page.detail.element === currentPage) ? 'border-indigo-500 text-gray-700' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
              )}
            >
              {page.detail.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
function ProfileDropDown(){
  const userNavigation = [
    { name: 'Sign out', href: 'handleLogout' },
  ]
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
    
  function handleLogout(){
    authCtx.logout()
    navigate('/login')    
  }
  return  <div className="sticky top-0 z-40 flex flex-row h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
              <div className="flex flex-1 flex-row-reverse gap-x-4 self-stretch lg:gap-x-6">
                  <div className="flex items-center gap-x-4 lg:gap-x-6">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">View notifications</span>
                      <DynamicHeroIcon icon={'BellIcon'} aria-hidden="true" className="h-6 w-6" />
                    </button>
                    <Menu as="div" className="relative">
                        <MenuButton className="-m-1.5 flex items-center p-1.5">
                          <span className="sr-only">Open user menu</span>
                          <span className="hidden lg:flex lg:items-center">
                            <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                              Vikas Minda
                            </span>
                            <DynamicHeroIcon icon={'ChevronDownIcon'} aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                          </span>
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          {userNavigation.map((item) => (
                            <MenuItem key={item.name}>
                              <a
                                href={item.href}
                                onClick={(e) => {
                                  e.preventDefault()
                                  if(item.href === 'handleLogout')
                                    handleLogout()
                                }}
                                className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                              >
                                {item.name}
                              </a>
                            </MenuItem>
                          ))}
                        </MenuItems>
                    </Menu>
                  </div>
              </div>
          </div> 
}
function NavBar({menu}){
  const [currentTab, setCurrentTab] = useState(menu[0]?.pageGroupId)
  const navigate = useNavigate();
  function handleNavigate(pageGroupId){
    setCurrentTab(pageGroupId)
    setPageGroupId(pageGroupId)
  }
  const {setPageGroupId} = useContext(AuthContext); // This will trigger TopBar useEffect
  useEffect(() => {
    console.log("NavBar useEffect - ")
    setPageGroupId(menu[0]?.pageGroupId)
  },[])

  return <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      alt="Your Company"
                      src={logo}
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {menu.map((item, index) => (
                            <li key={index}>
                              <a
                                onClick={e => {e.preventDefault(); handleNavigate(item.pageGroupId)}}
                                className={classNames(
                                  (currentTab === item.pageGroupId)
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                  'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                )}
                              >
                                <DynamicHeroIcon icon={item.detail.icon} 
                                  aria-hidden="true"
                                  className={classNames(
                                    (currentTab === item.pageGroupId) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0',
                                  )}
                                />
                                {item.detail.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>

                    </ul>
                  </nav>
              </div>
          </div>
}

export {SideBar}
