import { BrowserRouter, Routes, Route} from 'react-router-dom';
import React, { useState, useContext} from 'react';
import { SideBar } from './component/SideBar';
import { AuthContextProvider, AuthContext } from "./store/auth-context";
import { HeroScreen } from './screen/prelogin/HeroScreen';
import { ExampleScreen } from './screen/old/ExampleScreen';
import { SignUpScreen, EmailLoginScreen, VerifyAccountScreen, ForgotPasswordScreen, ResetPasswordScreen } from './screen/prelogin/SignUpScreen';
import { ListEnterpriseScreen } from './screen/admin/ListEnterpriseScreen';
import { SupplierCompanyDetailScreen, SupplierBankDetailScreen, SupplierBankDetail1Screen, SupplierOtherDetailScreen } from './screen/supplier/Profile';
import { RetailerCompanyDetailScreen, RetailerBankDetailScreen, RetailerBankDetail1Screen, RetailerOtherDetailScreen } from './screen/retailer/Profile';

function App() {
  return (
    <>
      <AuthContextProvider>
        <Navigation />
      </AuthContextProvider>
    </>
  );
}
function Navigation() {
  const {token, loading, isAuthenticated} = useContext(AuthContext);

  return (
    <html className="h-full bg-white">
      <head>
        <link rel="icon" href="/favicon.ico" sizes="16x16" type="image/x-icon" />
        <title>BeejCap - </title>
      </head>
      <body className="h-full">
         <BrowserRouter>
          <Routes>
          {isAuthenticated && (
            <Route path="/home/*" element={<SideBar />}>
                {
                    token?.menu?.map((page) => (
                      page.pages.map((route, index) => ( 
                        console.log(route.detail.element),
                        <Route
                          key={index}
                          path={route.detail.path}
                          element={React.createElement(componentMapping[route.detail.element])}
                        />
                  ))))}
            </Route> )}
            <Route path="ex" element={<ExampleScreen />} />
            <Route path="/" element={<HeroScreen />} />
            <Route path="/login" element={<EmailLoginScreen />} />
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
            <Route path="/resetPassword" element={<ResetPasswordScreen />} />
            <Route path="/verifyAccount" element={<VerifyAccountScreen />} />
          </Routes>
        </BrowserRouter>
      </body>
    </html>      
  );
}
const componentMapping = {
  ListEnterpriseScreen: ListEnterpriseScreen,
  SupplierCompanyDetailScreen:SupplierCompanyDetailScreen, 
  SupplierBankDetailScreen:SupplierBankDetailScreen,
  SupplierBankDetail1Screen:SupplierBankDetail1Screen,
  SupplierOtherDetailScreen:SupplierOtherDetailScreen,
  RetailerCompanyDetailScreen:RetailerCompanyDetailScreen,
  RetailerBankDetailScreen:RetailerBankDetailScreen,
  RetailerBankDetail1Screen:RetailerBankDetail1Screen,
  RetailerOtherDetailScreen:RetailerOtherDetailScreen
};

export default App;
