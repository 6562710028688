// Menu.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react'
import {
    Cog6ToothIcon,
    UsersIcon,
    HomeIcon
  } from '@heroicons/react/24/outline'
function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}
const menuConfig = // menuConfig.json
[
  {
    "title": "Home",
    "path": "/home",
    "subMenu": []
  },
  {
    "title": "Services",
    "path": "/services",
    "subMenu": [
      {
        "title": "Consulting",
        "path": "/services/consulting"
      },
      {
        "title": "Development",
        "path": "/services/development"
      }
    ]
  },
  {
    "title": "About",
    "path": "/about",
    "subMenu": []
  }
]


const Menu = ({ menuConfig }) => {
  return (
    <nav>
      <ul>
        {menuConfig.map((menuItem, index) => (
          <li key={index}>
            <Link to={menuItem.path}>{menuItem.title}</Link>
            {menuItem.subMenu && menuItem.subMenu.length > 0 && (
              <SubMenu subMenu={menuItem.subMenu} />
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};



const SubMenu = ({ subMenu }) => {
  return (
    <ul>
      {subMenu.map((subMenuItem, index) => (
        <li key={index}>
          <Link to={subMenuItem.path}>{subMenuItem.title}</Link>
        </li>
      ))}
    </ul>
  );
};

function NavBar( { navigation} ){
    const navigate = useNavigate();
    function handleNavigate(href){
      //setCurrentTab(href)
      navigate(href)
    }
    const [currentTab, setCurrentTab] = useState('/home')
    return <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        alt="Your Company"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        className="h-8 w-auto"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  onClick={e => {e.preventDefault(); handleNavigate(item.href)}}
                                  className={classNames(
                                    (item.href === currentTab)
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                  )}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className={classNames(
                                      (item.href === currentTab) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0',
                                    )}
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
  
                      </ul>
                    </nav>
                </div>
            </div>
}
function ExampleScreen(){
    
    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        alt="Your Company"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        className="h-8 w-auto"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                              <li key="Menu1">
                                <a>Menu1</a>
                                <ul role="list" className="flex flex-1 flex-col mx-5">
                                    <li>
                                        <ul role="list" className="-mx-2 space-y-1">
                                            <li key="Menu1">
                                                <a>Menu1</a>
                                            </li>
                                            <li key="Menu2">
                                                <a>Menu2</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                              </li>
                              <li key="Menu2">
                                <a>Menu2</a>
                              </li>
                          </ul>
                        </li>
  
                      </ul>
                    </nav>
                </div>
            </div>
      );
}
export {ExampleScreen}