
import * as HIcons from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function capitalizeAndSpace(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  //Outputs March 17, 2023
  function readableDate(timestamp){
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }
  function camelCaseToTitle(camelCaseString) {

    if (typeof camelCaseString !== 'string') {
      return '';
    }
    let result = camelCaseString.replace(/([A-Z])/g, ' $1').trim();
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }
  //unixTimestamp is the time in milliseconds
  function timeAgo(unixTimestamp) {
    const now = new Date().getTime(); // Current time in milliseconds
    const timestamp = unixTimestamp; // Convert Unix timestamp to milliseconds
    const diffInSeconds = Math.floor((now - timestamp) / 1000); // Difference in seconds
  
    // Calculate difference
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
  
    let result;
  
    if (diffInSeconds < minute) {
      result = `${diffInSeconds}s ago`;
    } else if (diffInSeconds < hour) {
      result = `${Math.floor(diffInSeconds / minute)}m ago`;
    } else if (diffInSeconds < day) {
      result = `${Math.floor(diffInSeconds / hour)}h ago`;
    } else if (diffInSeconds < week) {
      result = `${Math.floor(diffInSeconds / day)}d ago`;
    } else {
      result = `${Math.floor(diffInSeconds / week)}w ago`;
    }
    return result;
  }
  function extractFromJSON(json, paths, exclude) {
    const jp = require('jsonpath');
    let path_obj = []
    paths.forEach(path => {
      path_obj = [...path_obj, ...jp.paths(json, path)]
    })
    let path_str = []
    path_obj.forEach(path => {
      const label = path[path.length - 1]
      if(exclude.includes(label))
        return
      path_str.push({'p': path, 'label': label, 'path': jp.stringify(path)})
    })
    let fval = {}
    path_str.forEach(e => {
      let val = jp.query(json, e.path)
      if(!fval[e.label])
        fval[e.label] = []
      fval[e.label].push(val)
    })
    return fval
  }
  
const DynamicHeroIcon = ({ icon, ...props }) => {
  const TheIcon = HIcons[icon];
  if (!TheIcon) {
    console.error(`Icon "${icon}" does not exist in @heroicons/react/outline`);
    return null;
  }
  return <TheIcon {...props} />;
};

  
  export {classNames, capitalizeAndSpace, readableDate, timeAgo, camelCaseToTitle, extractFromJSON, DynamicHeroIcon}