import { sendPostRequest, API_CONFIG } from '../../helper/HttpHelper'
import { camelCaseToTitle, readableDate } from '../../component/Helper';
import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import {Modal} from '../../component/Modal'

function ListEnterpriseScreen() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()
  const {token} = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('')
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({content:'By clicking, you agree to approve the user', title:'Approve User!', 'action':handleApproval,  button_text:'Approve', getData:getSelectedUser})

  function getSelectedUser(){
    return selectedUser
  }
  function handleAction(email) {
    console.log('Handling action - ' + email)
    setSelectedUser(email)   
    //setMesg({...mesg, data:getSelectedUser})
    setOpen(true)
  }
  async function handleApproval(data){
    try{
      let res = await sendPostRequest(API_CONFIG.API_BASE_URL + 'admin/adminConfirmUserSignUp', { 'token': token, 'data':{ 'username': data } }) 
      console.log(res)
      if(res.status === 'error'){
        console.error(res.message)
        return
      }
      setOpen(false)
      setSelectedUser('')
    }catch(e){
      console.error(e)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try{
        let res = await sendPostRequest(API_CONFIG.API_BASE_URL + 'admin/listUnconfirmedUsers', { 'token': token }) 
        console.log(res)
        if(res.status === 'error' && ['TOKEN_INVALID', 'TOKEN_EXPIRED', 'TOKEN_NOT_SUPPLIED'].includes(res.code)){
          authCtx.logout();
          navigate('/login')
        }
        if(res.status === 'error'){
          console.error(res.message)
          return
        }
        setUsers(res.users)
      }catch(e){
        console.error(e)
      }
    }
    fetchData()
  }, [])
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {(open) && <Modal title={mesg.title} content={mesg.content} action={mesg.action} button_text={mesg.button_text} getData={getSelectedUser} closeModal={() => setOpen(false)}></Modal>}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">Enterprises</h1>
          <p className="mt-2 text-sm text-gray-700">
            All enterprises and their users.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Enterprise Domain
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Category
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    User Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created On
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {user.domain}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{camelCaseToTitle(user.enterprise_type)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{readableDate(user.created_datetime)}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 space-x-2">
                      <a onClick={() => handleAction(user.email)} className="text-indigo-600 hover:text-indigo-900">
                        Approve<span className="sr-only">, {user.name}</span>
                      </a>
                      <a href="#" className="text-indigo-600 hover:text-indigo-900">
                        Delete<span className="sr-only">, {user.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export { ListEnterpriseScreen }